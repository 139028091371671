
<!--
   loader.component.html
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
-->

<div class="app-loader-backdrop">
  <div class="app-loader__container">
    <div class="app-loader__title">
      {{ option.title | translate }}
      <i
        class="fa fa-times float-right"
        (click)="onClose()"
        *ngIf="option.showClose"
      ></i>
    </div>
    <div class="app-loader__body" [class.hide-icon]="option.hideIcon">
      <table>
        <td class="app-loader__body__icon" *ngIf="!option.hideIcon">
          <i [ngClass]="option.icon" *ngIf="option.icon; else loading">
            <span>
              <img class="pause" src="/assets/images/pause_120.png" alt="image" />
            </span>
          </i>
          <ng-template #loading>
            <img class="loading" src="/assets/images/loading.gif" alt="image" />
          </ng-template>
        </td>
        <td class="vertical">
          <tr class="app-loader__body__description" *ngIf="option.description">
            <td>{{ option.description | translate }}</td>
          </tr>
          <tr class="app-loader__body__loading" *ngIf="option.description">
            {{ option.loadingText | translate }}
          </tr>
        </td>
      </table>
    </div>
    <div *ngFor="let btn of option.buttons" class="app-loader__action">
      <button [ngClass]="btn.class" (click)="btn.onClick()">
        {{ btn.title }}
      </button>
    </div>
  </div>
</div>
